import { FormHelperText, Grid, Tooltip } from "@mui/material";
import FormField from "components/FormField/FormField";
import { Controller, useFormContext } from "react-hook-form";
import { UpdateProposalProductInputSchema } from "DDD/action-objects/StageProductUpdate";
import { useCallback, useContext, useEffect, useState } from "react";
import productAttributesNames from "utils/products/productAttributes";
import StageProductContext from "./forms/StageProductContext";
import pluralMeasurementMaterial from "constants/pluralMeasurementMaterial";
import MDAlert from "components/MDAlert";
import useGetOrganizationSettings from "hooks/organization/useGetOrganizationSettings";
import { applicationRateMappings } from "modules/products/data/applicationRateMappings";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import ProposalContext from "./providers/ProposalContextProvider";
import { capitalCase } from "change-case";
import lengthEndAdornment from "constants/lenghtEndAdornment";
import { applicationRateLabelMappings } from "utils/product-types/getJobMeasurementMethodOptions";
import { ProductProductionRateType, ProductType } from "generated/graphql";
import { ApolloError } from "@apollo/client";
import { snakeCase } from "change-case";
import WYSIWYG from "components/WYSIWYG/WYSIWYG";
import CurrencyFormField from "components/CurrencyFormField/CurrencyFormField";
import currencyEndAdornment from "constants/currencyEndAdornment";

function ProposalDefaultsAndInputs() {
  const { control, register, watch, getValues, setValue } =
    useFormContext<UpdateProposalProductInputSchema>();
  const { product, stage } = useContext(StageProductContext);
  const { proposal, inViewMode } = useContext(ProposalContext);
  const materialAttributes = watch("material.attributes", {});
  const { loading, data, error } = useGetOrganizationSettings();
  const averageWorkingHoursPerDay = +data?.["average_working_hours_per_day"] ?? 0;
  const isSubcontracted = product.material.subcontracted;
  const materialNeeded = getValues("material.materialNeeded");
  // material needed can be updated from here or from the MaterialCosts component and we need to keep the two components in sync
  const [materialNeededInput, setMaterialNeededInput] = useState(materialNeeded || "");
  const [statementOfWork, setStatementOfWork] = useState(getValues("material.statementOfWork"));

  const handleMaterialNeededInputChange = (input) => {
    setMaterialNeededInput(input);
    setValue("material.materialNeeded", input, { shouldDirty: true });
  };
  const isUnitprice = product.material?.unitPriceProduct;

  return (
    <Grid
      container
      spacing="16"
      sx={{
        justifyContent: { md: "space-between" },
      }}
    >
      <Grid className="table-form-field-inline-content">
        <Grid className="table-form-field-inline-items" sx={{ minWidth: "300px" }}>
          <Tooltip title="Profit Markup %">
            <FormField
              placeholder={product.material.markupPercentage}
              label="Profit Markup %"
              disabled={inViewMode}
              align="right"
              {...register("material.markup", { valueAsNumber: true })}
            />
          </Tooltip>
        </Grid>

        {Object.keys(materialAttributes).map(
          (
            attributeKey: keyof UpdateProposalProductInputSchema["material"]["attributes"],
            i: number
          ) => (
            <Grid key={i} data-attribute-key={attributeKey} sx={{ minWidth: "300px" }}>
              {attributeKey == "applicationRate" &&
              applicationRateMappings[product.material.organizationProductTypeName] ? (
                <Controller
                  name={`material.attributes.${attributeKey}`}
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <>
                        <Grid className="table-form-field-inline-items" md={12}>
                          <CustomSelect
                            label={
                              applicationRateLabelMappings[
                                product.material.organizationProductTypeName
                              ]
                            }
                            control={control}
                            fullWidth
                            selectVariant="standard"
                            disabled={inViewMode}
                            placeholder={`${product.material.attributes[attributeKey]}`}
                            data={
                              applicationRateMappings[product.material.organizationProductTypeName]
                            }
                            name={field.name}
                            {...lengthEndAdornment}
                          />
                        </Grid>
                        {error?.message && (
                          <FormHelperText sx={{ marginLeft: "0px" }}>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </>
                    );
                  }}
                />
              ) : (
                <>
                  {product.material.type === snakeCase(ProductType.SLURRY_SEAL) ? (
                    <Grid className="table-form-field-inline-items">
                      <FormField
                        disabled={inViewMode}
                        align="right"
                        label={
                          applicationRateLabelMappings[
                            product.material.organizationProductTypeName
                          ] + capitalCase(product.material.measurementJob)
                        }
                        placeholder={product.material.attributes[attributeKey]}
                        {...register(`material.attributes.${attributeKey}`, {
                          valueAsNumber:
                            productAttributesNames[attributeKey]?.valueAsNumber ?? false,
                        })}
                      />
                    </Grid>
                  ) : (
                    <Grid className="table-form-field-inline-items">
                      <Tooltip
                        title={
                          applicationRateLabelMappings[
                            product.material.organizationProductTypeName
                          ] && attributeKey === "applicationRate"
                            ? applicationRateLabelMappings[
                                product.material.organizationProductTypeName
                              ]
                            : productAttributesNames[attributeKey]?.label ??
                              `Missing ${attributeKey} Product Attributes`
                        }
                      >
                        <FormField
                          disabled={inViewMode}
                          align="right"
                          label={
                            applicationRateLabelMappings[
                              product.material.organizationProductTypeName
                            ] && attributeKey === "applicationRate"
                              ? applicationRateLabelMappings[
                                  product.material.organizationProductTypeName
                                ]
                              : productAttributesNames[attributeKey]?.label ??
                                `Missing ${attributeKey} Product Attributes`
                          }
                          placeholder={product.material.attributes[attributeKey]}
                          {...register(`material.attributes.${attributeKey}`, {
                            valueAsNumber:
                              productAttributesNames[attributeKey]?.valueAsNumber ?? false,
                          })}
                          {...productAttributesNames[attributeKey]?.endAdornment}
                        />
                      </Tooltip>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          )
        )}
        {isUnitprice && (
          <>
            <Grid className="table-form-field-inline-items" sx={{ minWidth: "300px" }}>
              <Tooltip title="Unit Price">
                <div>
                  <CurrencyFormField
                    label="Unit Price"
                    placeholder={(
                      +(product?.material?.unitPriceOverride ?? product?.material?.unitPrice) / 100
                    ).toFixed(2)}
                    disabled={inViewMode}
                    name="material.unitPrice"
                    control={control}
                    {...currencyEndAdornment}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid className="table-form-field-inline-items" sx={{ minWidth: "300px" }}>
              <Tooltip
                title={`Production Rate per Day in ${capitalCase(product.material.measurementJob)}`}
              >
                <FormField
                  placeholder={product.material.dailyUnitsComplete}
                  label={`Production Rate per Day in ${capitalCase(
                    product.material.measurementJob
                  )}`}
                  disabled={inViewMode}
                  align="right"
                  {...register("material.dailyUnitsComplete", { valueAsNumber: true })}
                />
              </Tooltip>
            </Grid>
          </>
        )}
        {isSubcontracted && (
          <>
            <Grid className="table-form-field-inline-items" sx={{ minWidth: "300px" }}>
              <Tooltip title="Subcontractor Cost">
                <div>
                  <CurrencyFormField
                    label="Subcontractor Cost"
                    placeholder={(+product.subcontractorCost / 100).toFixed(2)}
                    disabled={inViewMode}
                    align="right"
                    name="subcontractorCost"
                    control={control}
                    {...currencyEndAdornment}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid className="table-form-field-inline-items" sx={{ minWidth: "300px" }}>
              <Tooltip title="Subcontractor Name">
                <FormField
                  placeholder={product.subcontractorName}
                  label="Subcontractor Name"
                  disabled={inViewMode}
                  align="right"
                  {...register("subcontractorName")}
                />
              </Tooltip>
            </Grid>
            <Grid className="table-form-field-inline-items" sx={{ minWidth: "300px" }}>
              <Tooltip title="Subcontractor Proposal ID">
                <FormField
                  placeholder={product.subcontractorProposalId}
                  label="Subcontractor Proposal ID"
                  disabled={inViewMode}
                  align="right"
                  {...register("subcontractorProposalId")}
                />
              </Tooltip>
            </Grid>
            <Grid className="table-form-field-inline-items" sx={{ minWidth: "300px" }}>
              <Tooltip title="Overhead Cost Percentage">
                <FormField
                  placeholder={product.material.overheadPercentage}
                  label="Overhead Cost Percentage"
                  disabled={inViewMode}
                  {...register("material.overheadPercentage", { valueAsNumber: true })}
                />
              </Tooltip>
            </Grid>
            <Grid className="table-form-field-inline-items" sx={{ minWidth: "300px" }}>
              <Tooltip title="Indirect Cost Percentage">
                <FormField
                  placeholder={product.material.indirectPercentage}
                  label="Indirect Cost Percentage"
                  disabled={inViewMode}
                  align="right"
                  {...register("material.indirectPercentage", { valueAsNumber: true })}
                />
              </Tooltip>
            </Grid>
          </>
        )}
        {!isSubcontracted && !isUnitprice && (
          <>
            <Grid className="table-form-field-inline-items" sx={{ minWidth: "300px" }}>
              {product.material.productionRateType === ProductProductionRateType.TONS_PER_HOUR ? (
                <Tooltip title="Crew Production Rate (Tons per Hour)">
                  <FormField
                    placeholder={product.material.dailyUnitsComplete}
                    label="Crew Production Rate (Tons per Hour)"
                    disabled={
                      inViewMode ||
                      !!getValues("jobDurationOverride") ||
                      !!getValues("jobDurationDaysOverride")
                    }
                    align="right"
                    {...register("material.dailyUnitsComplete", { valueAsNumber: true })}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title={`Product Completion Rate per Day in ${capitalCase(
                    product.material.measurementJob
                  )}`}
                >
                  <FormField
                    placeholder={product.material.dailyUnitsComplete}
                    label={`Product Completion Rate per Day in ${capitalCase(
                      product.material.measurementJob
                    )}`}
                    disabled={
                      inViewMode ||
                      !!getValues("jobDurationOverride") ||
                      !!getValues("jobDurationDaysOverride")
                    }
                    align="right"
                    {...register("material.dailyUnitsComplete", { valueAsNumber: true })}
                  />
                </Tooltip>
              )}
            </Grid>
            <Grid className="table-form-field-inline-items" sx={{ minWidth: "300px" }}>
              <ProductCompletionRatePerHour
                averageWorkingHoursPerDay={averageWorkingHoursPerDay}
                loading={loading}
                error={error}
              />
            </Grid>
            {product.material.type === snakeCase(ProductType.ASPHALT) && (
              <Grid className="table-form-field-inline-items" sx={{ minWidth: "300px" }}>
                <Tooltip title="Asphalt Weight Conversion Factor">
                  <FormField
                    label="Asphalt Weight Conversion Factor"
                    placeholder={product.material.tonConversionFactor}
                    disabled={inViewMode}
                    align="right"
                    {...register("material.tonConversionFactor", { valueAsNumber: true })}
                  />
                </Tooltip>
              </Grid>
            )}
            {product.material.type === snakeCase(ProductType.EXCAVATE_OUT) && (
              <Grid className="table-form-field-inline-items" sx={{ minWidth: "300px" }}>
                <Tooltip title="Material Weight Conversion Factor">
                  <FormField
                    label="Material Weight Conversion Factor"
                    placeholder={product.material.tonConversionFactor}
                    disabled={inViewMode}
                    align="right"
                    {...register("material.tonConversionFactor", { valueAsNumber: true })}
                  />
                </Tooltip>
              </Grid>
            )}
          </>
        )}
      </Grid>

      <Grid item sx={{ marginTop: "10px" }} xs={12}>
        <Grid xs={12} md={12}>
          <Controller
            control={control}
            name="material.statementOfWork"
            render={({ field, fieldState: { error } }) => (
              <>
                <WYSIWYG
                  type="proposalProduct"
                  content={field.value}
                  onChange={(content) => setStatementOfWork(content)}
                  disabled={inViewMode}
                  title="Statement Of Work"
                  onBlur={() =>
                    setValue("material.statementOfWork", statementOfWork, { shouldDirty: true })
                  } // only start update product countdown onblur
                />
                {error && error?.message && (
                  <FormHelperText
                    sx={{
                      color: "red",
                    }}
                  >
                    {error.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </Grid>
      </Grid>
      {!isSubcontracted && !isUnitprice && (
        <>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item xs={12}>
            Calculated Inputs
          </Grid>
          <Grid className="table-form-field-inline-content">
            {product.material.type === snakeCase(ProductType.EXCAVATE_OUT) ? (
              <Grid className="table-form-field-inline-items">
                <FormField
                  label={`Material Excavated and Hauled in ${
                    pluralMeasurementMaterial[product.material.measurementMaterial]
                  }`}
                  placeholder={product.material.materialNeeded}
                  value={materialNeededInput}
                  align="right"
                  onChange={(e) => handleMaterialNeededInputChange(+e.target.value)}
                />
              </Grid>
            ) : (
              <Grid className="table-form-field-inline-items">
                <FormField
                  label={`Material Needed in ${
                    pluralMeasurementMaterial[product.material.measurementMaterial]
                  }`}
                  placeholder={product.material.materialNeeded}
                  value={materialNeededInput}
                  align="right"
                  onChange={(e) => handleMaterialNeededInputChange(e.target.value)}
                />
              </Grid>
            )}
            <Grid className="table-form-field-inline-items">
              <FormField
                label={`Coverage per ${capitalCase(
                  product.material.measurementMaterial
                )} in ${capitalCase(product.material.measurementJob)}`}
                placeholder={`Coverage per ${capitalCase(
                  product.material.measurementMaterial
                )} in ${capitalCase(product.material.measurementJob)}`}
                value={product.material.areaCoverage?.toFixed(2) || 0}
                align="right"
                disabled
              />
            </Grid>
            <Grid className="table-form-field-inline-items">
              <FormField
                label="Number of Days to Complete Stage"
                disabled={inViewMode || !!getValues("jobDurationOverride")}
                align="right"
                placeholder={
                  (product?.jobDurationOverride || product.jobDuration) > 0
                    ? (
                        (product?.jobDurationOverride || product.jobDuration) /
                        averageWorkingHoursPerDay
                      ).toFixed(1)
                    : 0
                }
                {...register("jobDurationDaysOverride", { valueAsNumber: true })}
              />
            </Grid>
            <Grid className="table-form-field-inline-items">
              <FormField
                label="Number of Hours to Complete Stage"
                disabled={inViewMode || !!getValues("jobDurationDaysOverride")}
                placeholder={product?.jobDurationOverride || product?.jobDuration}
                align="right"
                {...register("jobDurationOverride", { valueAsNumber: true })}
              />
            </Grid>
            <Grid className="table-form-field-inline-items">
              <FormField
                label="Round Trip Travel Time in Hours"
                disabled={inViewMode}
                placeholder={((stage.distanceSeconds / 3600) * 2).toFixed(2)}
                align="right"
                {...register("roundTripTravelTimeHours", { valueAsNumber: true })}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

function ProductCompletionRatePerHour({
  averageWorkingHoursPerDay,
  loading,
  error,
}: {
  averageWorkingHoursPerDay: number;
  loading: boolean;
  error: ApolloError;
}) {
  const { product } = useContext(StageProductContext);

  const dailyUnitsComplete =
    product.material.dailyUnitsCompleteOverride ?? product.material.dailyUnitsComplete;
  let productCompletionRatePerHour =
    +dailyUnitsComplete > 0 && averageWorkingHoursPerDay > 0
      ? +dailyUnitsComplete / averageWorkingHoursPerDay
      : 0;
  if (product.material.productionRateType === ProductProductionRateType.TONS_PER_HOUR) {
    productCompletionRatePerHour = +dailyUnitsComplete;
  }

  if (loading) {
    return <FormField label="Product Completion Rate per hour" disabled value="Loading ..." />;
  }

  if (error) {
    return (
      <MDAlert color="error">
        Please make sure to set the Average working hours per day in your organization settings.
      </MDAlert>
    );
  }

  return (
    !loading &&
    !error && (
      <Tooltip
        title={`Product Completion Rate Per Hour (Avg ${averageWorkingHoursPerDay}Working Hours Per Day)`}
      >
        <div>
          <FormField
            label={`Product Completion Rate per hour (Avg ${averageWorkingHoursPerDay} Working Hours Per Day)`}
            disabled
            align="right"
            value={productCompletionRatePerHour?.toFixed(2)}
          />
        </div>
      </Tooltip>
    )
  );
}

export default ProposalDefaultsAndInputs;
