import { Grid, Icon, Skeleton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Company, UserPermissions } from "generated/graphql";

import { range } from "ramda";
import MDAlert from "components/MDAlert";
import getLocalString from "constants/Localization";
import { getRoute } from "utils/routing";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import DataTable from "components/Tables/DataTable/DataTable";
import TableSkeleton from "components/TableSkeleton/TableSkeleton";
import useProposalsTable from "hooks/proposals/useProposalsTable";
import { useState } from "react";
import { useCheckPermissions } from "hooks/authentication/useCheckPermissions";
import { checkPermissions } from "helpers/functions";

function ProposalsLoader() {
  return (
    <Grid container spacing={6}>
      {range(0, 6).map((index) => (
        <Grid key={index} item xs={12} md={6} xl={4}>
          <Skeleton variant="rectangular" width="100%" height={400} />
        </Grid>
      ))}
    </Grid>
  );
}

export const CompanyProposals: React.FC<{ company: Company }> = ({ company }) => {
  const { loading, data, tableData, error, filtering, pagination } = useProposalsTable({
    companyId: company.id,
  });

  const { hasPermissions } = useCheckPermissions();

  if (loading) {
    return <TableSkeleton numberOfLines={pagination.pageSize} />;
  }

  const hasProposals = data?.length > 0;

  return (
    <>
      <MDBox pt={2} px={2} lineHeight={1.25} display="flex" justifyContent="space-between">
        <MDBox>
          <MDTypography variant="h6" fontWeight="medium">
            Proposals
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Company Proposals
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox>
          {hasPermissions(UserPermissions.MANAGE_PROPOSALS) && (
            <MDTypography
              as={Link}
              to={getRoute("companies.proposals.create", [["companyId", company.id]])}
            >
              <MDButton color="blue">
                <Icon>add</Icon>&nbsp; Add New
              </MDButton>
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        {error && (
          <MDBox>
            <MDAlert color="error">
              {getLocalString("generic.failure")}. {error.message}
            </MDAlert>
          </MDBox>
        )}
        {!error && hasProposals && (
          <DataTable
            table={tableData}
            canSearch
            pagination={pagination}
            filtering={filtering}
            isSorted={true}
          />
        )}
        {!hasProposals && (
          <MDTypography variant="body2">{getLocalString("proposals.empty")}</MDTypography>
        )}
      </MDBox>
    </>
  );
};
