import { Company } from "generated/graphql";
import { mergeAll, omit } from "ramda";
import { zodUploadValidator } from "utils/validations/isValidUpload";
import * as z from "zod";

export const schema = z.object({
  id: z.string().min(1),
  name: z.string().min(1, "Name is Required"),
  status: z.string().min(1, "Status is Required"),
  website: z.string().optional(),
  email: z.string().email("Email is Required"),
  logo: z.string().nullable().optional(),
  addressLine1: z.string().nullable().optional(),
  addressLine2: z.string().nullable().optional(),
  addressCity: z.string().nullable().optional(),
  addressState: z.string().nullable().optional(),
  addressZip: z.string().nullable().optional(),
  addressCountry: z.string().nullable().optional(),
  phone: z.string().nullable().optional(),
  contacts: z.object({
    upsert: z
      .array(
        z.object({
          id: z.string().optional(),
          title: z.string().optional(),
          firstName: z.string().min(1, "First Name is Required"),
          lastName: z.string().min(1, "Last Name is Required"),
          role: z.string().optional(),
          phone: z.string().optional(),
          email: z.string().email().optional(),
          primary: z.boolean(),
          SMSNotifications: z.boolean().optional().default(true),
        })
      )
      .refine(
        (val) => {
          if (!val || val?.length === 0) {
            return false;
          }

          if (
            !val.find((contact: any) => (contact as unknown as Company["contacts"][number]).primary)
          ) {
            return false;
          }

          return true;
        },
        {
          message: "Primary Contact is Required",
        }
      ),
  }),
  notificationTypes: z.object({
    CONTACT: z.boolean().optional().default(true),
    JOB: z.boolean().optional().default(true),
    JOB_PHASE: z.boolean().optional().default(true),
    OPPORTUNITY: z.boolean().optional().default(true),
    PROPOSAL: z.boolean().optional().default(true),
  }),
});

export const defaultValues = {
  name: "",
  status: "",
  website: "",
  email: "",
  phone: "",
  addressLine1: "",
  addressLine2: "",
  addressCity: "",
  addressState: "",
  addressZip: "",
  addressCountry: "US",
  contacts: {
    upsert: [],
  },
  notificationTypes: {},
};

export const getDefaultValues = (initialValues: Company) => {
  // We need to put the company contacts under upsert
  return mergeAll([
    defaultValues,
    omit(["contacts"])(initialValues),
    {
      contacts: { upsert: initialValues.contacts },
    },
  ]);
};
