import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Company, UserPermissions } from "generated/graphql";

import MDAlert from "components/MDAlert";
import getLocalString from "constants/Localization";
import { getRoute } from "utils/routing";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import DataTable from "components/Tables/DataTable/DataTable";
import TableSkeleton from "components/TableSkeleton/TableSkeleton";
import useOpportunitiesTable from "../../hooks/opportunities/useOpportunitiesTable";
import { useCheckPermissions } from "hooks/authentication/useCheckPermissions";

export const CompanyOpportunities: React.FC<{ company: Company }> = ({ company }) => {
  const { loading, data, tableData, error, filtering, pagination } = useOpportunitiesTable({
    companyId: company.id,
  });

  const { hasPermissions } = useCheckPermissions();

  if (loading) {
    return <TableSkeleton numberOfLines={pagination.pageSize} />;
  }

  const hasOpportunities = data?.length > 0;

  return (
    <>
      <MDBox pt={2} px={2} lineHeight={1.25} display="flex" justifyContent="space-between">
        <MDBox>
          <MDTypography variant="h6" fontWeight="medium">
            Opportunities
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Company Opportunities
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox>
          {hasPermissions(UserPermissions.MANAGE_OPPORTUNITIES) && (
            <MDTypography
              as={Link}
              to={getRoute("opportunities.create", [["companyId", company.id]])}
            >
              <MDButton color="blue">
                <Icon>add</Icon>&nbsp; Add New
              </MDButton>
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        {error && (
          <MDBox>
            <MDAlert color="error">
              {getLocalString("generic.failure")}. {error.message}
            </MDAlert>
          </MDBox>
        )}
        {!error && hasOpportunities && (
          <DataTable
            table={tableData}
            canSearch
            pagination={pagination}
            filtering={filtering}
            isSorted={true}
          />
        )}
        {!hasOpportunities && (
          <MDTypography variant="body2">{getLocalString("proposals.empty")}</MDTypography>
        )}
      </MDBox>
    </>
  );
};
