import { useWith, path, split } from "ramda";

//    dotPath :: String -> Object -> Any
const dotPath = useWith(path, [split(".")]);

const language = "EN";

const localizations = {
  authentication: {
    "agree-to-terms-and-conditions": {
      EN: "You must agree to the terms and conditions to create an account",
    },
    "email-verify-title": {
      EN: "Complete Sign Up",
    },
    "email-verify-failure-description": {
      EN: "Verification link invalid",
    },
    "signup-success": {
      EN: "Successfully signed up",
    },
    "signup-failure": {
      EN: "Failed to sign up",
    },
  },
  users: {
    "update-error": {
      EN: "Failed to update the User Account. Please refresh and try again later.",
    },
    "update-failed": {
      EN: "Failed to update the User Account. Please try again.",
    },
    "update-success": {
      EN: "Successfully updated the User Account.",
    },
    "invite-success": {
      EN: "Successfully invited User",
    },
    "invite-failure": {
      EN: "Failed to invite User. Please try again in a few minutes.",
    },
  },
  subscription: {
    "url-failed": {
      EN: "Failed to get subscription details. Please try again later.",
    },
  },
  companies: {
    "archive-success": {
      EN: "Successfully archived Company",
    },
    "archive-failure": {
      EN: "Failed to archive the Company",
    },
    "create-failure": {
      EN: "Failed to create Company.",
    },
    "create-exists": {
      EN: "Failed to create Company. A similar one already exists",
    },
    "create-success": {
      EN: "Successfully created Company.",
    },
    "load-failure": {
      EN: "Failed to load the Company details. Please try again later.",
    },
    "update-success": {
      EN: "Successfully updated the Company.",
    },
    "update-failure": {
      EN: "Failed to update the Company. Please try again later.",
    },
    "merge-success": {
      EN: "Successfully merged.",
    },
    "merge-failure": {
      EN: "Failed to merge the Companies. Please try again later.",
    },
    "upload-url-success": {
      EN: "Uploaded image successfully",
    },
    "upload-url-failure": {
      EN: "Failed to upload image",
    },
  },
  opportunities: {
    "cancel-success": {
      EN: "Successfully cancelled Opportunity",
    },
    "cancel-failure": {
      EN: "Failed to cancel the Opportunity",
    },
    "create-failure": {
      EN: "Failed to create Opportunity.",
    },
    "create-success": {
      EN: "Successfully created Opportunity.",
    },
    "load-failure": {
      EN: "Failed to load the Opportunity details. Please try again later.",
    },
    "proposal-success": {
      EN: "Successfully created Proposal.",
    },
    "proposal-failure": {
      EN: "Failed to create Proposal.",
    },
    "update-success": {
      EN: "Opportunity updated successfully!",
    },
    "update-failure": {
      EN: "Could not update Opportunity",
    },
    "create-status-failure": {
      EN: "Could not create opportunity status",
    },
    "create-status-success": {
      EN: "Opportunity status created succesfully!",
    },
    "delete-failure": {
      EN: "Could not delete opportunity",
    },
    "delete-success": {
      EN: "Opportunity deleted!",
    },
  },
  organization: {
    "create-address-success": {
      EN: "Successfully created new address",
    },
    "update-proposal-content-success": {
      EN: "Successfully updated proposal content",
    },
    "update-proposal-content-failure": {
      EN: "Faild to  update proposal content",
    },
    "create-address-failure": {
      EN: "Failed to create new address",
    },
    "update-address-success": {
      EN: "Success updating address!",
    },
    "update-address-failure": {
      EN: "Failed to update address",
    },
    "delete-address-success": {
      EN: "Success deleting the address!",
    },
    "delete-address-failure": {
      EN: "Failed to delete the address. Please check if the address is primary or not",
    },
    "create-additional-cost-success": {
      EN: "Successfully created new additional cost",
    },
    "create-additional-cost-failure": {
      EN: "Failed to create new additional cost",
    },
    "update-additional-cost-success": {
      EN: "Success updating additional cost!",
    },
    "update-additional-cost-failure": {
      EN: "Failed to update additional cost",
    },
    "delete-additional-cost-success": {
      EN: "Success deleting the additional cost!",
    },
    "delete-additional-cost-failure": {
      EN: "Failed to delete the additional cost",
    },
    "create-equipment-piece-success": {
      EN: "Successfully created new equipment piece",
    },
    "create-equipment-piece-failure": {
      EN: "Failed to create new equipment piece",
    },
    "update-equipment-piece-success": {
      EN: "Success updating equipment piece!",
    },
    "update-equipment-piece-failure": {
      EN: "Failed to update equipment piece",
    },
    "delete-equipment-piece-success": {
      EN: "Success deleting the equipment piece!",
    },
    "delete-equipment-piece-failure": {
      EN: "Failed to delete the equipment piece",
    },
    "create-transfer-truck-success": {
      EN: "Successfully created new transfer truck",
    },
    "create-transfer-truck-failure": {
      EN: "Failed to create new transfer truck",
    },
    "update-transfer-truck-success": {
      EN: "Success updating transfer truck!",
    },
    "update-transfer-truck-failure": {
      EN: "Failed to update transfer truck",
    },
    "delete-transfer-truck-success": {
      EN: "Success deleting the transfer truck!",
    },
    "delete-transfer-truck-failure": {
      EN: "Failed to delete the transfer truck",
    },
    "create-plant-success": {
      EN: "Successfully created new plant",
    },
    "create-plant-failure": {
      EN: "Failed to create new plant",
    },
    "update-plant-success": {
      EN: "Success updating plant!",
    },
    "update-plant-failure": {
      EN: "Failed to update plant",
    },
    "delete-plant-success": {
      EN: "Success deleting the plant!",
    },
    "delete-plant-failure": {
      EN: "Failed to delete the plant",
    },
    "create-plant-product-type-success": {
      EN: "Material created successfully!",
    },
    "create-plant-product-type-failure": {
      EN: "Failed to create material",
    },
    "create-crew-success": {
      EN: "Successfully added new crew",
    },
    "create-crew-failure": {
      EN: "Failed to add new crew",
    },
    "update-crew-success": {
      EN: "Success updating crew!",
    },
    "update-crew-failure": {
      EN: "Failed to update crew",
    },
    "delete-crew-success": {
      EN: "Success removing the crew!",
    },
    "delete-crew-failure": {
      EN: "Failed to remove the crew",
    },
    "update-email-template-success": {
      EN: "Email Template updated successfully",
    },
    "update-email-template-failed": {
      EN: "Failed to update Email Template",
    },
    "update-email-template-preview-success": {
      EN: "Email Template Preview sent successfully",
    },
    "update-email-template-preview-failed": {
      EN: "Failed to send Email Template Preview",
    },
    "get-email-template-failed": {
      EN: "Failed to get Email Template",
    },
    "create-employee-success": {
      EN: "Employee created successfully!",
    },
    "create-employee-failure": {
      EN: "Could not create employee",
    },
    "update-employee-success": {
      EN: "Employee updated successfully!",
    },
    "update-employee-failure": {
      EN: "Could not update employee",
    },
    "delete-employee-success": {
      EN: "Employee deleted successfully!",
    },
    "delete-employee-failure": {
      EN: "Could not delete employee",
    },
    "update-success": {
      EN: "Organization updated successfully!",
    },
    "update-failure": {
      EN: "Could not update Organization",
    },
    "add-document-success": {
      EN: "Document added successfully!",
    },
    "add-document-failure": {
      EN: "Could not add document",
    },
    "delete-document-success": {
      EN: "Document deleted successfully!",
    },
    "delete-document-failure": {
      EN: "Could not delete document",
    },
    "get-linked-service-auth-url-success": {
      EN: "Processing...",
    },
    "get-linked-service-auth-url-failure": {
      EN: "Could not link service",
    },
    "finalize-linked-service-account-success": {
      EN: "Service Linked!",
    },
    "finalize-linked-service-account-failure": {
      EN: "Could not link service",
    },
    "save-quickbooks-income-account-success": {
      EN: "Settings saved!",
    },
    "save-quickbooks-income-account-failure": {
      EN: "Could not save Quickbooks settings",
    },
    "save-quickbooks-integration-data-success": {
      EN: "Data saved!",
    },
    "save-quickbooks-integration-data-failure": {
      EN: "Could not save data to sync with Quickbooks",
    },
    "create-job-invoice-on-linked-service-success": {
      EN: "Operation successful!",
    },
    "create-job-invoice-on-linked-service-failure": {
      EN: "Operation failed",
    },
    "unlink-service-success": {
      EN: "Service unlinked",
    },
    "unlink-service-failure": {
      EN: "Could not unlink service",
    },
    "generate-report-success": {
      EN: "Report generated!",
    },
    "generate-report-failure": {
      EN: "Something went wrong",
    },
    "create-data-upload-success": {
      EN: "Successfully created new data import",
    },
    "create-data-upload-failure": {
      EN: "Failed to create new data import",
    },
    "update-sales-tax-success": {
      EN: "Saved!",
    },
    "update-sales-tax-failure": {
      EN: "Could not update sales tax",
    },
    "update-organization-proposal-defaults-success": {
      EN: "Proposal defaults updated",
    },
    "update-organization-proposal-defaults-failure": {
      EN: "Could not update proposal defaults",
    },
    "remove-job-from-queue-success": {
      EN: "Successfully deleted Job from queue",
    },
    "remove-job-from-queue-failure": {
      EN: "Failed to delete Job from queue",
    },
    "delete-project-file-success": {
      EN: "Deleted!",
    },
    "delete-project-file-failure": {
      EN: "Failed to delete",
    },
    "create-project-file-folder-success": {
      EN: "Folder Created!",
    },
    "create-project-file-folder-failure": {
      EN: "Could not create folder",
    },
    "add-project-file-success": {
      EN: "Uploaded!",
    },
    "add-project-file-failure": {
      EN: "Upload failed",
    },
    "update-roles-with-permissions-success": {
      EN: "Roles updated successfully",
    },
    "update-roles-with-permissions-failure": {
      EN: "Failed to update roles",
    },
  },
  "product-types": {
    "create-success": {
      EN: "Successfully created Product Type.",
    },
    "create-failure": {
      EN: "Failed to create Product Type",
    },
    "update-success": {
      EN: "Successfully updated Product Type.",
    },
    "update-failure": {
      EN: "Failed to update Product Type",
    },
    "read-failure": {
      EN: "Failed to read Product Type. Please refresh the page and try again.",
    },
    "delete-success": {
      EN: "Successfully deleted Product Type.",
    },
    "delete-failure": {
      EN: "Failed to delete Product Type. Cannot delete a Product Type that has at least 1 Product.",
    },
    "delete-confirm": {
      EN: "Are you sure you want to delete this product type?",
    },
  },
  products: {
    "create-success": {
      EN: "Successfully created Product",
    },
    "create-failure": {
      EN: "Failed to create Product. Please try again later.",
    },
    "update-success": {
      EN: "Successfully updated Product",
    },
    "update-failure": {
      EN: "Failed to update Product. Please try again later.",
    },
    "delete-success": {
      EN: "Successfully deleted Product",
    },
    "delete-failure": {
      EN: "Failed to delete Product. Please try again later.",
    },
    "duplicate-success": {
      EN: "Product duplicated successfully",
    },
    "duplicate-failure": {
      EN: "Failed to duplicate Product. Please try again later",
    },
  },
  generic: {
    failure: {
      EN: "Something went wrong. Please try again in a few minutes",
    },
  },
  proposals: {
    empty: {
      EN: "No proposals found.",
    },
    "create-success": {
      EN: "Successfully created Proposal",
    },

    "create-failure": {
      EN: "Failed to create Proposal",
    },
    "delete-success": {
      EN: "Successfully deleted Proposal",
    },

    "delete-failure": {
      EN: "Failed to delete Proposal",
    },
    "update-success": {
      EN: "Successfully updated the Proposal",
    },
    "update-failure": {
      EN: "Failed to update the proposal. Please try again.",
    },
    "add-stage-success": {
      EN: "Successfully added stage",
    },
    "add-stage-failure": {
      EN: "Failed to add stage",
    },
    "add-product-to-proposal-stage-success": {
      EN: "Successfully added product to proposal stage.",
    },
    "add-product-to-proposal-stage-failure": {
      EN: "Failed to add product to proposal stage",
    },
    "add-stage-address-success": {
      EN: "Successfully added address to the proposal stage",
    },
    "add-stage-address-failure": {
      EN: "Failed to add address to the proposal stage",
    },
    "update-proposal-plant-product-success": {
      EN: "Successfully updated the proposal plant product",
    },
    "update-proposal-plant-product-failure": {
      EN: "Failed to update the proposal plant product",
    },
    "update-proposal-product-success": {
      EN: "Successfully updated the proposal product",
    },
    "update-proposal-product-failure": {
      EN: "Failed to update the proposal product",
    },
    "add-proposal-equipment-piece-success": {
      EN: "Successfully added equipment piece to proposal product",
    },
    "add-proposal-equipment-piece-failure": {
      EN: "Failed to add equipment piece to proposal product",
    },
    "add-proposal-equipment-pieces-success": {
      EN: "Successfully added equipment piece(s) to proposal product",
    },
    "add-proposal-equipment-pieces-failure": {
      EN: "Failed to add equipment piece(s) to proposal product",
    },
    "add-proposal-crew-success": {
      EN: "Successfully added crew to proposal product",
    },
    "add-proposal-crew-failure": {
      EN: "Failed to add crew to proposal product",
    },
    "add-proposal-crews-success": {
      EN: "Successfully added crew(s) to proposal product",
    },
    "add-proposal-crews-failure": {
      EN: "Failed to add crew(s) to proposal product",
    },
    "add-proposal-additional-cost-success": {
      EN: "Successfully added additional cost to proposal product",
    },
    "add-proposal-additional-cost-failure": {
      EN: "Failed to add additional cost to proposal product",
    },
    "add-proposal-additional-costs-success": {
      EN: "Successfully added additional cost to proposal product",
    },
    "add-proposal-additional-costs-failure": {
      EN: "Failed to add additional cost to proposal product",
    },
    "add-proposal-file-success": {
      EN: "Successfully added file to the proposal product",
    },
    "add-proposal-file-failure": {
      EN: "Failed to add the file to the proposal product",
    },
    "delete-proposal-file-success": {
      EN: "Successfully deleted the proposal file",
    },
    "delete-proposal-file-failure": {
      EN: "Failed to delete the proposal file",
    },
    "send-success": {
      EN: "Successfully sent the proposal",
    },
    "send-failure": {
      EN: "Failed to send the proposal",
    },
    "set-to-draft-success": {
      EN: "Successfully set proposal to Draft",
    },
    "set-to-draft-failure": {
      EN: "Failed to set proposal to Draft",
    },
    "close-proposal-success": {
      EN: "Successfully closed the Proposal",
    },
    "close-proposal-failure": {
      EN: "Failed to close the Proposal",
    },
    "update-proposal-info-success": {
      EN: "Successfully updated the Proposal info",
    },
    "update-proposal-info-failure": {
      EN: "Failed to update the Proposal info",
    },
    "send-proposal-to-recipient-success": {
      EN: "Successfully sent the Proposal to Recipient",
    },
    "send-proposal-to-recipient-failure": {
      EN: "Failed to send the Proposal to Recipient",
    },
    "delete-proposal-recipient-success": {
      EN: "Successfully deleted the Proposal Recipient",
    },
    "delete-proposal-recipient-failure": {
      EN: "Failed to delete the Proposal Recipient",
    },
    "cancel-proposal-success": {
      EN: "Successfully cancelled the Proposal",
    },
    "cancel-proposal-failure": {
      EN: "Failed to cancel the Proposal",
    },
    "duplicate-proposal-success": {
      EN: "Successfully duplicated the Proposal",
    },
    "duplicate-proposal-failure": {
      EN: "Failed to duplicate the Proposal",
    },
    "delete-proposal-product-success": {
      EN: "Successfully deleted the proposal product",
    },
    "delete-proposal-product-failure": {
      EN: "Failed to delete the proposal product",
    },
    "delete-proposal-stage-success": {
      EN: "Successfully deleted the proposal stage",
    },
    "delete-proposal-stage-failure": {
      EN: "Failed to delete the proposal stage",
    },
    "delete-map-image-success": {
      EN: "Map Image deleted successfully",
    },
    "delete-map-image-failure": {
      EN: "Failed to delete Map Image",
    },
    "add-truck-to-proposal-product-success": {
      EN: "Truck added to proposal product successfully!",
    },
    "add-truck-to-proposal-product-failure": {
      EN: "Failed to add truck to proposal product.",
    },
    "remove-truck-from-proposal-product-success": {
      EN: "Truck removed from proposal product successfully!",
    },
    "remove-truck-from-proposal-product-failure": {
      EN: "Failed to remove truck from proposal product",
    },
    "preview-proposal-success": {
      EN: "Generated proposal preview successfully",
    },
    "preview-proposal-failure": {
      EN: "Failed to generate proposal preview",
    },
    "update-proposal-sales-taxes-success": {
      EN: "Saved",
    },
    "update-proposal-sales-taxes-failure": {
      EN: "Operation failed",
    },
    "toggle-proposal-product-optionality-success": {
      EN: "Saved!",
    },
    "toggle-proposal-product-optionality-failure": {
      EN: "Operation failed",
    },
  },
  notifications: {
    "create-success": {
      EN: "Successfully created new notification triggger.",
    },
    "create-failure": {
      EN: "Failed to create notification triggger. Please try again later.",
    },
    "update-success": {
      EN: "Successfully updated  notification triggger.",
    },
    "update-failure": {
      EN: "Failed to update notification triggger. Please try again later.",
    },
  },

  jobs: {
    empty: {
      EN: "No jobs found",
    },
    "update-job-project-manager-success": {
      EN: "Project manager updated successfully!",
    },
    "update-job-project-manager-failure": {
      EN: "Could not update project manager",
    },
    "update-job-billing-status-success": {
      EN: "Billing status updated successfully!",
    },
    "update-job-billing-status-failure": {
      EN: "Could not update billing status",
    },
    "update-job-notes-success": {
      EN: "Job notes updated successfully!",
    },
    "update-job-notes-failure": {
      EN: "Could not updatejob notes",
    },
    "update-job-project-file-folder-id-success": {
      EN: "Updated!",
    },
    "update-job-project-file-folder-id-failure": {
      EN: "Something went wrong",
    },
  },
  jobPhases: {
    "update-job-phase-status-success": {
      EN: "Job Phase status updated successfully!",
    },
    "update-job-phase-status-failure": {
      EN: "Could not update job phase status",
    },
    "add-job-phase-costs-success": {
      EN: "Job Phase costs successfully added!",
    },
    "add-job-phase-costs-failure": {
      EN: "Could not add job phase costs",
    },
    "add-job-phase-file-success": {
      EN: "Successfully added file to the job phase",
    },
    "add-job-phase-file-failure": {
      EN: "Failed to add the file to the job phase",
    },
    "update-notes-success": {
      EN: "Successfully updated Job Phase notes",
    },
    "update-notes-failure": {
      EN: "Failed to update Job Phase notes",
    },
    "delete-job-phase-file-success": {
      EN: "Successfully deleted the job phase file",
    },
    "delete-job-phase-file-failure": {
      EN: "Failed to delete the job phase file",
    },
    "reordering-job-phase-success": {
      EN: "Successfully updated Job Schedule",
    },
    "reordering-job-phase-failure": {
      EN: "Failed to update Job Schedule",
    },
    "update-work-order-items-success": {
      EN: "Sucessfully updated Work Order items",
    },
    "update-work-order-items-failure": {
      EN: "Failed to update Work Order items",
    },
    "update-job-phase-plant-success": {
      EN: "Sucessfully updated Job Phase Plant",
    },
    "update-job-phase-plant-failure": {
      EN: "Failed to update  Job Phase Plant",
    },
  },
  customEvents: {
    "create-custom-event-success": {
      EN: "Successfully created custom event",
    },
    "create-custom-event-failure": {
      EN: "Failed to  create custom event",
    },
    "update-custom-event-success": {
      EN: "Successfully updated custom event",
    },
    "update-custom-event-failure": {
      EN: "Failed to  update custom event",
    },
    "schedule-custom-event-block-success": {
      EN: "Successfully scheduled custom event block",
    },
    "schedule-custom-event-block-failure": {
      EN: "Failed to schedule custom event block",
    },
  },
};

type LocalizationStrings = typeof localizations;

type AllKeys<T> = T extends any ? keyof T : never;

type NestedKeys = {
  [Key in keyof LocalizationStrings]: `${Key}.${AllKeys<LocalizationStrings[Key]>}`;
}[keyof LocalizationStrings];

const getLocalString = (key: NestedKeys) => {
  const translation = dotPath(key)(localizations) as Record<typeof language, string> | undefined;

  if (!(typeof translation === "undefined")) {
    return translation[language];
  }

  return "Missing Localization";
};

export default getLocalString;
