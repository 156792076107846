import { AddCircle } from "@mui/icons-material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  EquipmentPiece,
  CreateEquipmentPieceInput,
  EquipmentPieceChargeType,
} from "generated/graphql";
import useCreateEquipmentPiece from "hooks/organization/useCreateEquipmentPiece";
import useModalState from "hooks/useModalState";
import Modal from "modules/Modal/Modal";
import React, { useCallback, useState } from "react";
import Create from "modules/OrganizationEquipmentPieces/Create";
import MDAlert from "components/MDAlert";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import { Box, Card, Icon, IconButton, Tab, Tabs } from "@mui/material";
import EquipmentPieceOptions from "modules/equipmentPieces/EquipmentPieceOptions";
import MDBox from "components/MDBox";
import AutoComplete from "modules/AutoComplete/AutoComplete";
import ScrollAsyncAutocomplete from "components/ScrollAsyncAutocomplete/ScrollAsyncAutocomplete";
import MultiSelectExtension from "components/Shared/MultiSelectExtension/MultiSelectExtension";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <MDTypography>{children}</MDTypography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddEquipmentCost({
  submitMsg = "Add Equipment Cost",
  onAdd,
  allowCreate = true,
  isJobCosting,
}: {
  onAdd: (
    ids: string[] | number[] | EquipmentPiece[],
    setEps?: React.Dispatch<React.SetStateAction<any[]>>
  ) => Promise<void> | void;
  allowCreate?: boolean;
  submitMsg?: string;
  isJobCosting?: boolean;
}) {
  const { open, onClose, onOpen } = useModalState();
  const [createEquipmentPiece, { loading, error, isSuccess, getData }] = useCreateEquipmentPiece();
  const [equipmentPieceId, setEquipmentPieceId] = useState<string | null>(null);
  const [eps, setEps] = useState([]);
  const [openTab, setOpenTab] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setOpenTab(newValue);
  };

  const onSubmit = useCallback(
    async (values: CreateEquipmentPieceInput) => {
      const formattedInput = {
        ...values,
        rate: Math.floor(values.rate * 100),
        fuelSurcharge: Math.floor(values.fuelSurcharge * 100),
      };
      const result = await createEquipmentPiece({ variables: { input: formattedInput } });
      const data = getData(result);
      const success = isSuccess(result.data);
      if (isSuccess) {
        onClose();
        onAdd([data.id], setEps);
      }
      return { success, data };
    },
    [createEquipmentPiece]
  );

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <MDBox p={3}>
          {error && <MDAlert>{parseGraphQLError(error)}</MDAlert>}
          {openTab === 0 ? (
            <MDTypography variant="h6" py={3}>
              Add Equipment Piece(s)
            </MDTypography>
          ) : (
            <MDTypography variant="h6" py={3}>
              Create Equipment Piece to add
            </MDTypography>
          )}

          <Box sx={{ width: "100%" }}>
            {allowCreate && (
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={openTab}
                  onChange={handleChangeTab}
                  aria-label="add equipment piece tab"
                >
                  <Tab label="Add Equipment Piece" {...a11yProps(0)} />
                  <Tab label="Create Equipment Piece to add" {...a11yProps(1)} />
                </Tabs>
              </Box>
            )}
            <TabPanel value={openTab} index={0}>
              <EquipmentPieceOptions>
                {({ equipmentPiecesById, equipmentPieceOptions, loading, search, pagination }) => (
                  <Card>
                    <MDBox p={3}>
                      <MDBox minWidth="160px">
                        <MDTypography variant="h6">Equipment Piece(s)</MDTypography>
                      </MDBox>
                      <MultiSelectExtension
                        tags={eps}
                        setTags={setEps}
                        options={equipmentPieceOptions}
                        onChange={(value) => setEquipmentPieceId(value as string)}
                        loading={loading}
                        pagination={pagination}
                        search={search}
                        id={equipmentPieceId}
                        label="Equipment Piece"
                        nameCheckFn={equipmentPiecesById}
                      />
                      <MDBox textAlign="right">
                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            const equipmentPieces = isJobCosting
                              ? eps.map((ep) => equipmentPiecesById[ep])
                              : eps;
                            onAdd(equipmentPieces, setEps);
                            onClose();
                          }}
                          disabled={eps.length < 1}
                        >
                          Add
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Card>
                )}
              </EquipmentPieceOptions>
            </TabPanel>
            <TabPanel value={openTab} index={1}>
              <Create onSubmit={onSubmit} loading={loading} />
            </TabPanel>
          </Box>
        </MDBox>
      </Modal>
      <MDButton variant="gradient" startIcon={<AddCircle />} onClick={onOpen}>
        {submitMsg}
      </MDButton>
    </>
  );
}
