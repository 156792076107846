import { Grid, Icon, Skeleton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Company, Job, UserPermissions } from "generated/graphql";

import { range } from "ramda";
import getLocalString from "constants/Localization";
import { getRoute } from "utils/routing";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import { JobCard } from "components/JobCard/JobCard";
import { useGetJobs } from "hooks/jobs/useGetJobs";
import MDAlert from "components/MDAlert";
import useJobsTable from "hooks/jobs/useJobsTable";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import DataTable from "components/Tables/DataTable/DataTable";
import TableSkeleton from "components/TableSkeleton/TableSkeleton";
import { useCheckPermissions } from "hooks/authentication/useCheckPermissions";

function JobsLoader() {
  return (
    <Grid container spacing={6}>
      {range(0, 6).map((index) => (
        <Grid key={index} item xs={12} md={6} xl={4}>
          <Skeleton variant="rectangular" width="100%" height={400} />
        </Grid>
      ))}
    </Grid>
  );
}

export const CompanyJobs: React.FC<{ company: Company }> = ({ company }) => {
  const { offset } = usePaginatedVariables();
  const { loading, data, tableData, error, pagination, filtering } = useJobsTable({
    first: offset.first,
    page: offset.page,
    companyId: company.id,
  });
  const { hasPermissions } = useCheckPermissions();

  if (loading) {
    return <TableSkeleton numberOfLines={pagination.pageSize} />;
  }

  const hasJobs = data?.length > 0;

  return (
    <>
      <MDBox pt={2} px={2} lineHeight={1.25} display="flex" justifyContent="space-between">
        <MDBox>
          <MDTypography variant="h6" fontWeight="medium">
            Jobs
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Company Jobs
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox>
          {hasPermissions(UserPermissions.MANAGE_COMPANIES) && (
            <MDTypography
              as={Link}
              to={getRoute("companies.jobs.create", [["companyId", company.id]])}
            >
              <MDButton color="blue">
                <Icon>add</Icon>&nbsp; Add New
              </MDButton>
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        {error && (
          <MDBox>
            <MDAlert color="error">
              {getLocalString("generic.failure")}. {error.message}
            </MDAlert>
          </MDBox>
        )}
        {!error && hasJobs && (
          <DataTable
            table={tableData}
            canSearch
            pagination={pagination}
            filtering={filtering}
            isSorted={true}
          />
        )}
        {!hasJobs && <MDTypography variant="body2">{getLocalString("jobs.empty")}</MDTypography>}
      </MDBox>
    </>
  );
};
